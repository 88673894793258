import SubtitleDashboard from '@components/general/Titles/SubtitleDashboard'
import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Servidores = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard title={"Servidores"}/>

            <p className='mt-3'>
                Se tienen contratados un servicio de alojamiento y dos servidores VPS para gestionar las aplicaciones y dominios de la empresa.
            </p>

            <p>
                A continuación se desglosan los principales servicios que se gestionan en cada uno de los alojamientos contratados:
                
                <div className="my-3"></div>

                <SubtitleDashboard title={"Hosting semi dedicado"}/>

                <p className='mt-3'>
                    Dentro del servicio de hosting semi dedicado se tienen las bases de datos de los siguientes cursos: 

                    <ul>
                        <li>Curso inglés</li>
                        <li>Curso lectura veloz</li>
                    </ul>
                </p>

                <SubtitleDashboard title={"VPS #1"}/>

                <p className='mt-3'>
                    En el primer servidor VPS se tienen alojadas las siguientes aplicaciones: 

                    <ul>
                        <li>SIM</li>
                        <li>Buscador de usuarios</li>
                    </ul>
                </p>

                  <SubtitleDashboard title={"VPS #2"} />

                  <p className='my-3'>
                      En el segundo servidor se tienen alojadas las siguientes aplicaciones

                      <ul>
                          <li>Plataforma Click+</li>
                          <li>Plataforma Click+ (QA)</li>
                      </ul>
                  </p>

                  <TitleDashboard title={"Comandos más usados en servidores SSH"}/>

                  <p className="my-3">
                    A continuación se listan los comandos más usados en las terminales SSH de los servidores.
                    <ul>
                        <li><code>forever list</code>: lista los servicios forever levantados.</li>
                        <li><code>forever restart &lt;PID&gt;</code>: Reiniciar un servicio de forever.</li>
                        <li><code>systemctl status nginx</code>: Revisar el estatus del servicio de nginx en el servidor.</li>
                        <li><code>systemctl restart nginx</code>: Reiniciar el servicio de nginx</li>
                        <li><code>systemctl start nginx</code>: Iniciar el servicio de nginx</li>
                        <li><code>systemctl status mongod</code>: Revisar el estatus del servicio de Mongo db</li>
                        <li><code>systemctl restart mongod</code>: Reiniciar el servicio de Mongo db</li>
                        <li><code>systemctl start mongod</code>: Iniciar el servicio de Mongo db</li>
                        <li><code>nvm current</code>: Muestra la versión de Node actual</li>
                        <li><code>nvm use &lt;version&gt;</code>: Indicar que version usar con nvm</li>
                        

                    </ul>
                  </p>

                  <TitleDashboard title={"Gestión de llamadas de RP"}/>

                  <p className="my-3">
                    Dentro del departamento de relaciones públicas se hacen llamadas todo el día, cada llamada hecha se almacena para temas de calidad en el servicio, así para cualquier situación que se presente que requiera de revisar la llamada se tienen almacenadas dentro de uno de los servidores VPS.
                  </p>

                  <p>Las llamadas se almacenan en el VPS#1, se almacenan y hay que gestionar ese almacenamiento para evitar que se quede sin espacio el servidor, es por ello que se siguen las siguientes reglas a la hora de gestionar dicho almacenamiento:</p>

                  <p>
                    <ul>
                        <li>Se tiene que cuidar que el porcentaje de espacio no alcance de preferencia el 80% de su capacidad, ya que esto puede provocar una caida de los servicios dados de alta en el servidor y se suspenderán las aplicaciones hasta que se libere espacio.</li>
                        <li>
                            Las llamadas se gestionan mes con mes, dejando el mes actual y el mes anterior para hacer uso de ellas si es necesario, el resto de las llamadas ya no son consideradas de utilidad.
                        </li>
                    </ul>
                  </p>
                    

            </p>
        </Col>
    </Row>
  )
}

export default Servidores