import TitleDashboard from '@components/general/Titles/TitleDashboard'
import { Alert } from '@mui/material'
import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const Plataforma = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard  title={'Reinicio de plataforma'}/>
            <p className="mt-4">
                <span>Existen ocasiones en las que el servicio de la plataforma se queda congelado, la señal de que se necesita reiniciar el servicio de la plataforma es cuando se intenta iniciar sesión en la plataforma y se queda cargando la información.</span>
            </p>

            <p>Para solucionar esto hay que acceder a la aplicacion <b>Solar-Putty</b> creando una nueva conexión SSH con la dirección <b>75.102.23.17</b> la cual se encarga de almacenar la aplicación de la plaforma.</p>

            <Alert severity="info">En caso de no contar con las sesiones registradas solicitar al administrador encargado de gestionar las sesiones.</Alert>

            <Image className='my-4' width="800" src={require('../src/solarPanel.png')} />

            <p>Una vez se cree la conexión se escribe el comando <code>forever list</code> se despliegan los servicios que estan corriendo en el servidor:</p>
            
            <Image className='my-4' width="800" src={require('../src/foreverList.png')} />

            <p>Para reiniciar el servicio se escribe el comando <code>forever restart </code> seguido del pid del servicio, este se ubica justo antes del path del archivo logfile de cada servicio </p>

            <Image className='my-4' width="800" src={require('../src/foreverRestart.png')} />
            
            <p>Con esto, el servicio se reincia sin interrupciones y permitira a los usuarios ingresar de nuevo a la plataforma.</p>


        </Col>
    </Row>
  )
}

export default Plataforma