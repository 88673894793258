import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import ModalValidacion from './Modals/ModalValidacion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faClose, faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import InsertarRegistro from './Modals/InsertarRegistro';
import EditarRegistro from './Modals/EditarRegistro';
import EliminarRegistro from './Modals/EliminarRegistro';
import { MaterialReactTable } from 'material-react-table';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { Box, IconButton } from '@mui/material';
import { FaEdit, FaPowerOff, FaTrash } from 'react-icons/fa';



const DashboardSeguridad = () => {

    //Al iniciar el componente consultar la info de los accesos
    //Listar la informacion en un datatable
    //Resto del CRUD de los accesos (Editar la informacion, eliminar el acceso...)

    const [registroInsertado, setRegistroInsertado] = useState(false);

    const [registroModificado, setRegistroModificado] = useState(false);

    const [registroEliminado, setRegistroEliminado] = useState(false);

    const [indexEdicion, setIndexEdicion] = useState(0);

    const [indexEliminar, setIndexEliminar] = useState(0);

    //states para validacion de usuario
    const [open, setOpen] = useState(false);

    const [validado, setValidado] = useState(false);

    const [registros, setRegistros] = useState([]);

    const [showPassword, setShowPassword] = useState(false);
    
    //states para insertar un registro

    const [verModalRegistro, setVerModalRegistro] = useState(false);

    const [verModalEdicion, setVerModalEdicion] = useState(false);

    const [verModalEliminar, setVerModalEliminar] = useState(false);

    const handleCancelarRegistro = () => setVerModalRegistro(false);
    const handleOpenRegistro = () => setVerModalRegistro(true);

    const handleOpenEliminarRegistro = () => setVerModalEliminar(true);
    const handleCancelarEliminar = () => setVerModalEliminar(false);


    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const editarRegistro = (index) => {
        setIndexEdicion(index)        
       setVerModalEdicion(true);

    }

    const elimnarRegistro = (index) => {
        setIndexEliminar(index);
        handleOpenEliminarRegistro();
    }


    const handleCancelarEdicion = () => setVerModalEdicion(false);



    useEffect(() => {
     handleOpen();
    }, [])

    useEffect(() => {
        if(registroInsertado || registroModificado || registroEliminado){
            handleOpen()

        
        }
    }, [registroInsertado, registroModificado, registroEliminado])
    
    
    const columnas = useMemo(() => [
        {
          accessorKey: "cuenta",
          header: "Cuenta",
        },
        {
          accessorKey: "nombreCuenta",
          header: "Nombre",
        },
        {
            accessorKey: "usuario",
            header: "Acceso",
          },
          
        {
          accessorKey: "clave",
          header: "Contraseña",
          Cell: ({ cell }) => (
              <div className="text-center" style={{width: "250px"}}>
                  <InputGroup className="mb-3">
                      <InputGroup.Text style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)} id="basic-addon1"><FontAwesomeIcon icon={faEye} color="gray" /></InputGroup.Text>
                      <Form.Control
                          value={cell.row.original.clave}
                          readOnly
                          type={showPassword ? 'text' : 'password'}
                          placeholder="Username"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                      />
                  </InputGroup>
              </div>
          ),
          width: '80%',
        },
        {
          accessorKey: "anotaciones",
          header: "Anotaciones",
        },
      ]);
    
      

  return (
    <Container fluid className='mt-2'>

        <Row>
            <Col>
                <ModalValidacion registroInsertado={registroInsertado} registroModificado={registroModificado} registroEliminado={registroEliminado} setRegistroInsertado={setRegistroInsertado} setRegistroEliminado={setRegistroEliminado} setRegistroModificado={setRegistroModificado} setRegistros={setRegistros} validado={validado} setValidado={setValidado} open={open} handleClose={handleClose} />
            </Col>
        </Row>

        {verModalRegistro ? (
            <Row>
                <Col>
                    <InsertarRegistro setRegistroInsertado={setRegistroInsertado} handleCancelarRegistro={handleCancelarRegistro} verModalRegistro={verModalRegistro}/>
                </Col>
            </Row>
        ): null}

        {verModalEdicion ? (
            <Row>
                <Col>
                    <EditarRegistro infoRegistro={registros[indexEdicion]} setRegistroModificado={setRegistroModificado} handleCancelarEdicion={handleCancelarEdicion} verModalEdicion={verModalEdicion} />
                </Col>
            </Row>
        ): null}

        {verModalEliminar ? (
            <Row>
                <Col>
                    <EliminarRegistro infoRegistro={registros[indexEdicion]} setRegistroEliminado={setRegistroEliminado} handleCancelarEliminar={handleCancelarEliminar} verModalEliminar={verModalEliminar} />
                </Col>
            </Row>
        ): null}

        {!validado ? (
            <Row>
                <Col className='mt-5 shadow-lg text-center' style={{ backgroundColor: "#f2f2f2", height: "500px", borderRadius: "20px" }}>
                    <div className="pt-5">
                        <FontAwesomeIcon icon={faClose} color='red' size='10x' />
                        <h2>Se necesita validar tus credenciales</h2>

                        <Button className='btn btn-primary' onClick={handleOpen}>Acceder</Button>

                    </div>
                </Col>
            </Row>
        ): (
            <Row className="pb-5">
                <Col className="col-12">
                    <Row>
                        <Col>
                            <Button onClick={handleOpenRegistro} disabled={!validado} className='btn btn-success'>Nuevo registro</Button>
                        </Col>
                    </Row>
                </Col>
                <Col className='mt-5 shadow-lg text-center' style={{ backgroundColor: "#f2f2f2", height: "500px", borderRadius: "20px" }}>
                    {registros.length> 0 ? (
                            //   <Row>
                            //       <Col>
                            //           <div className="table-responsive">
                            //               <table class="table table-responsive table-sm table-striped table-bordered">
                            //                   <thead>
                            //                       <tr>
                            //                           <th scope="col">#</th>
                            //                           <th scope="col">Cuenta</th>
                            //                           <th scope="col">Nombre</th>
                            //                           <th scope="col">Acceso</th>
                            //                           <th scope="col">Usuario</th>
                            //                           <th scope="col">Contraseña</th>
                            //                           <th scope="col">Anotaciones</th>
                            //                           <th scope="col">Acciones</th>
                            //                       </tr>
                            //                   </thead>
                            //                   <tbody>
                            //                       {registros.map((registro, indexRegistro) => (
                            //                           <tr key={indexRegistro}>
                            //                               <td >{indexRegistro + 1}</td>
                            //                               <td>{registro.cuenta}</td>
                            //                               <td>{registro.nombreCuenta}</td>
                            //                               <td>{registro.acceso}</td>
                            //                               <td>{registro.usuario}</td>
                            //                               <td >
                            //                                   <InputGroup className="mb-3">
                            //                                       <InputGroup.Text style={{ cursor: "pointer" }} onClick={() => setShowPassword(!showPassword)} id="basic-addon1"><FontAwesomeIcon icon={faEye} color="gray" /></InputGroup.Text>
                            //                                       <Form.Control
                            //                                           value={registro.clave}
                            //                                           readOnly
                            //                                           type={showPassword ? 'text' : 'password'}
                            //                                           placeholder="Username"
                            //                                           aria-label="Username"
                            //                                           aria-describedby="basic-addon1"
                            //                                       />
                            //                                   </InputGroup>
                            //                               </td>
                            //                               <td>{registro.anotaciones}</td>
                            //                               <td>
                            //                                   <Row>
                            //                                       <Col className="col-6">
                            //                                           <Button onClick={()=> elimnarRegistro(indexRegistro)} className='btn btn-danger'><FontAwesomeIcon icon={faTrash} size='1x' /></Button>
                            //                                       </Col>
                            //                                       <Col className="col-6">
                            //                                           <Button onClick={()=> editarRegistro(indexRegistro)} className='btn btn-warning'><FontAwesomeIcon icon={faEdit} size='1x' /></Button>
                            //                                       </Col>
                            //                                   </Row>
                            //                               </td>
                            //                           </tr>
                            //                       ))}
                            //                   </tbody>
                            //               </table>
                            //           </div>
                            //       </Col>
                            //   </Row>

                        <Row className="my-3 mb-5">
                        <Col>
                        {/* <DataTablesRecipient  columns={dataTableColumns} data={consejos} /> */}
                        <MaterialReactTable
                            localization={MRT_Localization_ES}
                            columns={columnas}
                            data={registros}
                            enableRowActions
                            positionActionsColumn="last"
                            renderRowActions={({ row, table }) => (
                            <Box sx={{ display: "flex", flexWrap: "nowrap", gap: "8px" }}>
                                <IconButton
                                color="secondary"
                                id={row.original.idRegistro - 1}
                                onClick={()=> editarRegistro(row.id)}
                                >
                                <FaEdit className="text-primary" />
                                </IconButton>

                                <IconButton
                                    color="secondary"
                                    id={`${row.original.idRegistro - 1}`}
                                    
                                    onClick={()=> elimnarRegistro(row.id)}
                                >
                                    <FaTrash color="red" />
                                </IconButton>
                                
                            </Box>
                            )}
                        />
                        </Col>
                        </Row>
                    ): (
                        <Row className="mt-5">
                            <Col className='text-center'>
                                <FontAwesomeIcon icon={faBoxOpen} size='5x' />
                                <h2>No hay registros por el momento</h2>
                            </Col>
                        </Row>
                    )}
                </Col>
            </Row>
        )}
    </Container>
  )
}

export default DashboardSeguridad