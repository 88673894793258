import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const Redes = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard title={"Redes de la empresa"}/>

            <p className='mt-3'>
                Dentro de la empresa se tiene la siguiente infraestructura en redes: 
            </p>

            <Image className='my-4' width="800" src={require('../src/Redes.png')} />

        </Col>
    </Row>
  )
}

export default Redes