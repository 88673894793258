import TitleDashboard from '@components/general/Titles/TitleDashboard'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const IncidentesRP = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard title={"Incidentes en Relaciones Públicas"}/>

            <p className="my-3">
                Han habido situaciones en las que solicitan apoyo para acceder a la aplicación móvil con la que hacen llamadas en el departamento de RP, al iniciar sesión le indica que hubo un problema y no le permite al usuario acceder a la app, a continuación se presentan los pasos para solucionar el error:
                <br/>

                <ul>
                    <li>Se ingresa a la base de datos llamada <b>'nzsgkwnk_sim'</b> ubicada en el VPS#1.</li>
                    <li>Existe una segunda base de datos llamada <b>'nzsgkwnk_simv2'</b>, se tiene que corroborar que en ambas bases de datos, en la tabla de usuario tengan el mismo estado cada uno de los campos para que de esta manera el sistema pueda comparar las credenciales del usuario y permitirle entrar a la aplicación ya que en casos como este se tienen distintos valores en los campos del usuario en las bases de datos, entonces no le permite acceder por dicha diferencia.</li>
                    <li>Después de esto el usuario debería poder acceder a la app</li>
                </ul>
            </p>
        </Col>
    </Row>
  )
}

export default IncidentesRP