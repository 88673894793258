import SubtitleDashboard from '@components/general/Titles/SubtitleDashboard'
import TitleDashboard from '@components/general/Titles/TitleDashboard'
import { Alert } from '@mui/material'
import React from 'react'
import { Col, Image, Row } from 'react-bootstrap'

const AppsExternas = () => {
  return (
    <Row>
        <Col>
            <TitleDashboard title={"Aplicaciones"} />

            <p className='mt-3'>
                Existen otras aplicaciones a parte de la plataforma Click+, el <b>Buscador de usuarios</b> y la aplicacion <b>SIM</b> de las cuales hacen uso en el departamento de relaciones públicas.
            </p>

            <SubtitleDashboard title={"Buscador de usuarios"} />

            <p className="mt-3">
                El buscador de usuarios es una aplicación creada con el framework <b>Next js</b> que nos ayuda dentro del departamento de relaciones públicas a obtener listas de empresas a las cuales poder contactar para realizar sus actividades.   
            </p>
            
            <Image className='my-4' width="800" src={require('../src/buscadorUsuarios.png')} />

            <p className='mt-3'>
                Existen ocasiones en las que por algún motivo el servicio de la app se cae y hay que reiniciar el servicio de Forever si es que se ha detenido el proceso.
                <br/>
                Hay situaciones en las que solo el servicio de la base de datos se apaga y hay que volver a iniciarlo para hacer uso de la base de datos, esta aplicación hace uso de una base de datos con <b>Mongo DB</b>.
            </p>

            <p>Para revisar el servicio de mongo puedes usar el comando <code>service mongod status</code>.</p>

            <Image className='my-4' width="800" src={require('../src/mongoStatus.png')} />

            <p>En caso de ser necesario puedes activar el servicio usando <code>service mongod start</code>, esto permitirá hacer uso del servicio del buscador de usuarios.</p>

            <Alert className='mb-3' severity='info' >Si se necesita dar de alta el servicio en forever se usa el comando <code>forever start -l /home/nzsgkwnk/buscador-empresas/logfile.log --append -c "npm start" ./</code></Alert>

            <SubtitleDashboard title={"SIM"} />

            <p className="mt-3">
                La aplicación SIM permite a los supervisores del departamento de Relaciones Públicas llevar un control de las llamadas de sus asesores, es importante que siempre esté corriendo en producción ya que sin ella no pueden tener un correcto seguimiento de las llamadas hechas en el día.
            </p>
            <p>
                En caso de que la aplicación al intentar ingresar desde la dirección <b>sim.globalclickmexico.com</b> nos arroje un error y no cargue correctamente es necesario revisar el servicio forever desde una conexión SSH a la ip 75.102.23.122 que es donde se encuentra alojada la app.
            </p>

            <p>Una vez que se revise el servicio con <code>forever list</code> si está detenido el servicio hay que realizar lo siguiente:
                <ul>
                    <li>Revisar el servicio de nginx y comprobar que no se encuentre detenido usando el comando <code>service nginx status</code> o <code>systemctl status nginx</code>
                    <br/>
                    En caso de que lo necesite iniciar de nuevo el servicio de nginx.
                    </li>
                    <li>
                        Si nginx corre sin problemas verificamos la versión de nvm usando <code>nvm current</code>, en caso de que no esté usandose la versión <code>18.17.0</code> hay que usar esa version con el comando <code>nvm use 18.17.0</code> y una vez tengamos esa versión usandose correr el comando para levantar el servicio de forever <code>forever start -l /home/nzsgkwnk/sim/logfile.log --append -c "npm start" ./</code>
                        <br/>
                        Esto debería de iniciar de nuevo la app.
                    </li>
                    <li>Si ninguno de los pasos anteriores permite levantar el servicio de la aplicación nos colocamos en el directorio de la aplicación usando <code>cd sim</code> y escribimos <code>npm start</code>. Si existe un problema al correr la aplicación ahi nos lo mostrará, posteriormente hay que corregir ese error e intentar levantar el servicio con el comando de forever.</li>
                </ul>
            </p>

        </Col>
    </Row>
  )
}

export default AppsExternas