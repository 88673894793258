import React, { useEffect, useState } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import { InsertarRegistroAdministradores } from '@helpers/httpRequests/httpRequestAccesos/httpRequestAccesos';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};


const InsertarRegistro = ({ handleCancelarRegistro, verModalRegistro, setRegistroInsertado }) => {

    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const [formValues, setFormValues] = useState({
        cuenta: '',
        nombreCuenta: '',
        acceso: '',
        usuario: '',
        clave: '',
        anotaciones: '',
    })

    const handleOnChange = ({ currentTarget }) => {
        try {
            const { value, name } = currentTarget;

            setFormValues({
                ...formValues,
                [name]: value,
            })


        } catch (error) {
            console.log(error);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const {cuenta, nombreCuenta, acceso, usuario, clave, anotaciones} = formValues;
            await InsertarRegistroAdministradores({
                cuenta,
                nombreCuenta,
                acceso,
                usuario,
                clave,
                anotaciones
            });

            setRegistroInsertado(true);
            handleCancelarRegistro();


        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        setFormValues({
            ...formValues,
            cuenta: '',
            nombreCuenta: '',
            acceso: '',
            usuario: '',
            clave: '',
            anotaciones: '',
        })
    }, [handleCancelarRegistro])
    

    return (
        <Modal
            open={verModalRegistro}
            onClose={handleCancelarRegistro}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Container>
                    <Row className="mt-4">
                        <Col>
                            <Form onSubmit={handleSubmit}>

                                <Row>
                                    <Col>
                                        <Form.Label htmlFor="cuenta">Cuenta</Form.Label>
                                        <Form.Control
                                            autoComplete='off'
                                            list='suggestions'
                                            className="mb-3"
                                            name="cuenta"
                                            value={formValues.cuenta}
                                            onChange={handleOnChange}
                                            type="text"
                                            id="cuenta"
                                            placeholder="Global click"
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label htmlFor="nombreCuenta">Nombre de la cuenta</Form.Label>
                                        <Form.Control
                                            autoComplete='off'
                                            list='suggestions'
                                            className="mb-3"
                                            name="nombreCuenta"
                                            value={formValues.nombreCuenta}
                                            onChange={handleOnChange}
                                            type="text"
                                            id="nombreCuenta"
                                            placeholder='Ejemplo: Víctor Manuel Méndez'
                                        />
                                    </Col>
                                </Row>

                                <Form.Label htmlFor="acceso">Acceso</Form.Label>
                                <Form.Control
                                    autoComplete='off'
                                    list='suggestions'
                                    className="mb-3"
                                    name="acceso"
                                    value={formValues.acceso}
                                    onChange={handleOnChange}
                                    type="text"
                                    id="acceso"
                                    placeholder='Ejemplo: https://cursos.globalclickmexico.com/login'
                                />

                                <Form.Label htmlFor="usuario">Nombre de usuario</Form.Label>
                                <Form.Control
                                    autoComplete='off'
                                    list='suggestions'
                                    className="mb-3"
                                    name="usuario"
                                    value={formValues.usuario}
                                    onChange={handleOnChange}
                                    type="text"
                                    id="usuario"
                                />

                                <Form.Label htmlFor="inputPassword5">Contraseña</Form.Label>
                                <Form.Control
                                    className="mb-3"
                                    name="clave"
                                    value={formValues.password}
                                    onChange={handleOnChange}
                                    type={showPassword ? 'text' : 'password'}
                                    id="inputPassword5"
                                    aria-describedby="passwordHelpBlock"
                                />
                                <span style={{ cursor: 'pointer'}} onClick={handleShowPassword} className="mb-4">{showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}</span>

                                <FloatingLabel className="mt-3 mb-3" controlId="anotaciones" label="Anotaciones">
                                    <Form.Control
                                        name="anotaciones"
                                        value={formValues.anotaciones}
                                        onChange={handleOnChange}
                                        as="textarea"
                                        placeholder="Anotaciones de la cuenta"
                                        style={{ height: '100px' }}
                                    />
                                </FloatingLabel>


                                <div className='d-grid gap-2 mt-3'>
                                    <Button type="submit" className='btn btn-success'>Continuar</Button>
                                </div>
                            </Form>

                        </Col>
                    </Row>
                </Container>
            </Box>
        </Modal>

    )
}

export default InsertarRegistro