import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Container, Tabs, Tab, Button, OverlayTrigger, Tooltip, Alert, Image } from "react-bootstrap";
import { Link as LinkScroll } from "react-scroll";
import TitleDashboard from "@components/general/Titles/TitleDashboard";
import ComponenteEmbebido from "./components/ComponenteEmbebido";
import DespliegueSeccionesLecciones from "./components/DespliegueSeccionesLecciones";
import RankingTopCurso from "./components/RankingTopCurso";
import AcercaDelCurso from "./components/AcercaDelCurso";
import { getCursoByCodigo } from "@helpers/httpRequests/httpRequestsCursos/httpRequestsCursos";
import { ModalSweetAlert, Notificacion, NotificacionExtendida } from "@components/general/Alertas/Alertas";
import UserContext from "@context/User/UserContext";
import GenerarQR from "@components/general/GenerarQR/GenerarQR";
import { getPreguntasByCodigoCurso } from "@helpers/httpRequests/httpRequestsPreguntas/httpRequestsPreguntas";
import EvaluacionLeccion from "./components/EvaluacionLeccion";
import EvaluacionFinal from "./components/EvaluacionFinal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowUp, faChalkboardTeacher, faExpand, faPlay, faQuestion } from "@fortawesome/free-solid-svg-icons";
import DynamicBreadcrumb from "@components/general/DynamicBreadcrumb/DynamicBreadcrumb";
import { useRef } from "react";
import { Animated } from "react-animated-css";
import "@assets/css/components/ViewCurso/styles.css";
import { verificarCertificado } from "@helpers/httpRequests/httpRequestsCertificadosEmitidos/httpRequestsCertificadosEmitidos";
import { getLeccionByCodigo, getUltimaLeccionTomada } from "@helpers/httpRequests/httpRequestsLecciones/httpRequestsLecciones";
import multiViewComponentsPaths from "@helpers/multiViewComponentsPaths/multiViewComponentsPaths";
import Loader from "@helpers/loader/loader";
import useWindowResize from "@customHooks/useWindowResize";
import Joyride from "react-joyride";
import spanishOptionsJoyRide from "@helpers/spanishOptionsJoyRide/spanishOptionsJoyRide";
import "@assets/css/main_style.css";
import { getCalificacionPorCurso } from "@helpers/httpRequests/httpRequestsCursosUsuario/httpRequestsCursosUsuario";
import VerAvancesIngles from "./Modals/VerAvancesIngles";
import DespliegeUnidadesLecciones from "./components/DespliegeUnidadesLecciones";
import typesTiposUsuario from "@helpers/types/typesTiposUsuario";
import codigoCursosUsuarioInvitado from "@helpers/cursos/codigosCursosUsuarioInvitado";
import { Card } from "primereact/card";
import { getSeccionByCodigo } from "@helpers/httpRequests/httpRequestsSecciones/httpRequestsSecciones";
import { FaArrowCircleLeft, FaArrowLeft, FaArrowRight, FaDownload, FaFile, FaSpa, FaBookOpen } from "react-icons/fa";
import { MdOutlineCancel } from "react-icons/md";
import CertificadosPowerBI from "@helpers/certificadoPowerBi/CertificadosPowerBI";

export const ViewCurso = ({ showScrollButton, handleScrollToTop, handleChangeComponent, setModoConcentracion, modoConcentracion }) => {
	const siguienteLeccionRef = useRef();

	const leccionAnteriorRef = useRef();

	const siguienteSeccionRef = useRef();

	const seccionAnteriorRef = useRef();

	const joyrideRef = useRef(null);

	const [runTour, setRunTour] = useState(false);

	const [tourSteps, setTourSteps] = useState([
		{
			target: ".question-curso",
			content: "Te damos la bienvenida a la vista principal de tus cursos, en ella tienes toda la información del curso.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".listado-curso",
			content: "Lista todos los cursos disponibles y navega entre ellos.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".modo-concentracion",
			content: "Usa el modo de concentración para olvidarte de las distracciones y enfocarte totalmente en tu aprendizaje.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".evaluacionLeccion-curso",
			content: "Algunas lecciones tienen una evaluación en donde puedes practicar tus conocimientos y ver si es necesario o no repasar la lección.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".visor-curso",
			content: "Aquí se reproducen las lecciones del curso.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".contenido-curso",
			content: "Esta es la lista de todas las secciones con sus correspondientes lecciones del curso, haz click en la sección que desees para desplegar sus lecciones.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".ranking-curso",
			content:
				"Luego de realizar la evaluación final de cada curso tienes acceso al ranking del curso, en donde competirás con todos los ususarios de la plataforma. ¡Consigue la puntuación máxima para estar en primer lugar!.",
			disableBeacon: true,
			placement: "left-start",
		},
		{
			target: ".evaluacion-curso",
			content: "Cada curso tiene una evaluación final que te permitirá certificarte oficialmente.",
			disableBeacon: true,
			placement: "top-start",
		},
		{
			target: ".certificado-curso",
			content: "Luego de conseguir la puntuación minima aprobatoria en la evaluación final tendrás acceso al certificado del curso, en donde puedes descargarlo y conservarlo las veces que quieras.",
			disableBeacon: true,
			placement: "top-start",
		},

		{
			target: ".end-curso",
			content: "Da lo mejor de ti y consigue el mayor puntaje en el examen final, ¡Mucha suerte!.",
			disableBeacon: true,
			placement: "left-start",
		},
	]);

	const {
		size: { width },
	} = useWindowResize();

	//STATE PARA LOADER
	const [showLoader, setShowLoader] = useState(true);

	const [cursosUsuario, setCursosUsuario] = useState([]);

	//*STATES PARA EL MODO PANTALLA COMPLETA DEL VIDEO
	const iframeRef = useRef();

	const { contextValue, setContextValue } = useContext(UserContext);

	const { codigoCursoSeleccionado, usuarioLogeado, ultimoCursoTomado } = contextValue;

	const { idPerfil, rol, idUsuarioEmpresarial, correo, cursos } = usuarioLogeado;

	//*Informacion del curso
	const [infoCurso, setInfoCurso] = useState({});

	const [urlPlantilla, setUrlPlantilla] = useState(null);

	//*Informacion de la leccion
	const [nombreLeccionSeleccionada, setNombreLeccionSeleccionada] = useState("");

	//*STATE DONDE SE GUARDAN LAS PREGUNTAS DEL CURSO PARA LA COMPROBACION DE RESULTADOS DE LAS EVALUACIONES DE LAS LECCIONES
	const [preguntasEvaluacion, setPreguntasEvaluacion] = useState([]);

	//*STATE PARA CONTROLAR EL TAB DE LA PAGINA PRINCIPAL DEL COMPONENTE
	const [tab, setTab] = useState("contentCurso");

	//* STATE PARA MOSTRAR LA EVALUACION DE LECCION

	const [showEvaluacionLeccion, setShowEvaluacionLeccion] = useState(false);

	const [leccionActual, setLeccionActual] = useState(codigoCursoSeleccionado + "0101");

	//*STATE PARA MOSTRAR EL BOTON DE EVALUACION DE LECCION

	const [showBtnEvaluacion, setShowBtnEvaluacion] = useState(true);

	const [hasLeccionesIndividuales, setHasLeccionesIndividuales] = useState(true);

	const [urlLeccion, setUrlLeccion] = useState("");

	//*State para mostrar la evaluacion final del curso
	const [showEvaluacionFinal, setShowEvaluacionFinal] = useState(false);

	//*State para permitir la descarga del certificado
	const [certificadoDesbloqueado, setCertificadoDesbloqueado] = useState(false);

	const [puntajeCertificacion, setPuntajeCertificacion] = useState([]);

	const [mostrarBtnEvaluacion, setMostrarBtnEvaluacion] = useState(false);

	const [tipoLeccion, setTipoLeccion] = useState("captivate");

	const [nombreLeccion, setNombreLeccion] = useState(null);

	const [nombreSeccion, setNombreSeccion] = useState("");

	const [tieneMaterialApoyo, setTieneMaterialApoyo] = useState(false);

	//*Listado de lecciones para botones de reproductor

	const [listadoLecciones, setListadoLecciones] = useState([]);

	//*Arreglo con cursos que no deben mostrar boton para descargar plantilla-------------------------------------------------

	const cursosSinPlantilla = ["ING1", "ING2", "ING3", "CR23", "LV23", "PNLB", "CL23", "RC24", "EP24", "PV24"];

	//*Cursos que no usan el componente "componenteEmbebido"

	const cursosFueraComponente = ["ING1", "ING2", "ING3", "CR23", "PNLB", "CL23", "RC24", "EP24", "PV24"];

	const mostrarAlertaPremium = () => {
		return ModalSweetAlert({ title: "Esta función es exclusiva para usuarios Premium, adquiere tu licencia para tener acceso a todo el contenido de Click+.", icon: "info" });
	};
	//*MODALES DEL COMPONENTE===========================================================================

	const verAvancesIngles = () => {
		ModalSweetAlert({
			html: <VerAvancesIngles codigoCursoSeleccionado={codigoCursoSeleccionado} correo={correo} />,
			showConfirmButton: false,
			showCloseButton: true,
			width: "80%",
		});
	};

	const mostrarDocumento = async (codigoLeccion) => {
		try {
			const { data } = await getLeccionByCodigo(codigoLeccion);
			setUrlPlantilla(data.urlPlantilla);
			setNombreLeccion(data.nombre);
		} catch (error) {
			console.log(error);
		}
	};

	const handleStartTour = () => {
		if (rol === typesTiposUsuario.invitado) {
			return mostrarAlertaPremium();
		}

		setRunTour(!runTour);
	};

	const verificarEvaluacionLeccion = (codigoLeccion) => {
		//Evalua si en existen preguntas que correspondan con el codigo de la leccion que está seleccionada
		const resultado = preguntasEvaluacion.filter((pregunta) => pregunta.codigoLeccion === codigoLeccion);

		if (resultado.length === 0) {
			setMostrarBtnEvaluacion(false);
		} else {
			setMostrarBtnEvaluacion(true);
		}
	};

	let breadcrumbItemsInitialState;

	if (!codigoCursoSeleccionado.startsWith("ING") && !codigoCursoSeleccionado.startsWith("LV23")) {
		breadcrumbItemsInitialState = [
			{ title: `${infoCurso.nombre}`, onClick: () => { } },
			{ title: `${nombreSeccion}`, onClick: () => { } },
			{ title: `${nombreLeccion}`, onClick: () => { } },
		];
	} else {
		breadcrumbItemsInitialState = [{ title: `${infoCurso.nombre}`, onClick: () => { } }];
	}

	//*Funcion para el fullscreen
	const requestFullscreen = () => {
		iframeRef.current.requestFullscreen();
	};

	const verCursos = () => {
		handleChangeComponent(multiViewComponentsPaths.misCursos);
	};

	const comprobarCertificacion = async () => {
		try {
			const { data } = await getCalificacionPorCurso({
				codigoCurso: codigoCursoSeleccionado,
				idPerfil,
				rol,
				idUsuarioEmpresarial,
			});

			setPuntajeCertificacion(data.puntajeCertificacion);

			if (data.puntajeCertificacion >= 85) {
				setCertificadoDesbloqueado(true);
			}
		} catch (error) {
			console.log(error);
		}
	};

	//*MODO DE CONCENTRACION
	const FullScreenButton = () => {
		const element = document.documentElement; // Obtener el elemento raíz del documento

		if (element.requestFullscreen) {
			element.requestFullscreen();
		} else if (element.mozRequestFullScreen) {
			element.mozRequestFullScreen();
		} else if (element.webkitRequestFullscreen) {
			element.webkitRequestFullscreen();
		} else if (element.msRequestFullscreen) {
			element.msRequestFullscreen();
		}

		setModoConcentracion(true);

		// let sugerencias = document.getElementById("doorbell-button");

		// sugerencias.style.display = "none";
	};

	const cancelFullScreenButton = () => {
		if (document.exitFullscreen) {
			document.exitFullscreen();
		} else if (document.mozCancelFullScreen) {
			document.mozCancelFullScreen();
		} else if (document.webkitExitFullscreen) {
			document.webkitExitFullscreen();
		} else if (document.msExitFullscreen) {
			document.msExitFullscreen();
		}

		setModoConcentracion(false);

		// let sugerencias = document.getElementById("doorbell-button");

		// sugerencias.style.display = "block";
	};

	//*Manejo de lecciones desde botones del reproductor

	const siguienteLeccion = () => {
		let leccion = leccionActual.slice(6);

		leccion = parseInt(leccion);

		if (leccion !== listadoLecciones.length) {
			siguienteLeccionRef.current.handleSiguienteLeccion(leccion);
		} else {
			let siguienteSeccion = leccionActual.slice(4, 6);

			if (siguienteSeccion > 10) {
				siguienteSeccion = parseInt(siguienteSeccion) + 1;
			} else {
				siguienteSeccion = parseInt(siguienteSeccion) + 1;
			}

			siguienteSeccionRef.current.handleSiguienteSeccion(`${codigoCursoSeleccionado}${siguienteSeccion < 10 ? "0" + siguienteSeccion : siguienteSeccion}`);
		}
	};

	const leccionAnterior = () => {
		let leccion = leccionActual.slice(6);

		if (leccion !== "01") {
			leccion = parseInt(leccion) - 2;

			leccionAnteriorRef.current.handleLeccionAnterior(leccion);
		} else {
			console.log("SECCION ANTERIOR");
			let seccionAnterior = leccionActual.slice(4, 6);

			if (seccionAnterior > 10) {
				seccionAnterior = parseInt(seccionAnterior) - 1;
			} else {
				seccionAnterior = parseInt(seccionAnterior) - 1;
			}

			seccionAnteriorRef.current.handleSeccionAnterior(`${codigoCursoSeleccionado}${seccionAnterior < 10 ? "0" + seccionAnterior : seccionAnterior}`);
		}
	};

	//*INICIO DE USEEFFECT ======================================================================================
	useEffect(() => {
		handleScrollToTop();

		setShowLoader(true);

		const obtenerUltimaLeccionVista = async () => {
			try {
				const { data } = await getUltimaLeccionTomada(idPerfil);

				if (codigoCursoSeleccionado !== data.codigoLeccion.substring(0, 4)) {
					setLeccionActual(codigoCursoSeleccionado + "0101");
				} else {
					setLeccionActual(data.codigoLeccion);
				}
			} catch (error) {
				console.log(error);
				setLeccionActual(codigoCursoSeleccionado + "0101");
			}
		};

		obtenerUltimaLeccionVista();

		const consultarCurso = async () => {
			try {
				const { data, message } = await getCursoByCodigo(codigoCursoSeleccionado);

				if (data.length === 0) {
					return Notificacion("info", message);
				}

				setTieneMaterialApoyo(data.tieneMaterialApoyo);

				setInfoCurso(data);
			} catch (error) {
				console.log("Error al consultar la información del curso: ", error);

				NotificacionExtendida("error", "Algo sucedió al consultar las lecciones del curso, recarga la página");
			}
		};

		consultarCurso();

		const obtnerPreguntas = async () => {
			try {
				const { data } = await getPreguntasByCodigoCurso(codigoCursoSeleccionado);
				if (Array.isArray(data)) {
					setPreguntasEvaluacion(data);
				} else {
					setPreguntasEvaluacion([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtnerPreguntas();

		comprobarCertificacion();

		const obtenerUrlPlantilla = async () => {
			try {
				const { data } = await getLeccionByCodigo(leccionActual);
				setNombreLeccionSeleccionada(data.nombre);
				setTipoLeccion(data.tipo);
				if (data.urlPlantilla !== " ") {
					setUrlPlantilla(data.urlPlantilla);
				} else {
					setUrlPlantilla(null);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerUrlPlantilla();

		const obtenerNombrePrimeraSeccion = async () => {
			try {
				const { data } = await getSeccionByCodigo(codigoCursoSeleccionado + "01");
				setNombreSeccion(data.nombre);
			} catch (error) {
				console.log(error);
			}
		};

		obtenerNombrePrimeraSeccion();

		setTimeout(() => {
			try {
				const cursosUsuarioAux = JSON.parse(cursos);

				if (cursosUsuarioAux.find((curso) => curso.codigoCurso === codigoCursoSeleccionado) === undefined) {
					handleChangeComponent(multiViewComponentsPaths.home);

					return ModalSweetAlert({
						title: "No tienes acceso a este curso",
						html: (
							<p>
								Tu licencia contratada no tiene acceso a este curso.
								<br />
								Si quieres tener acceso a él, comunícate al 477 390 83 01 ext. 108 para solicitar informes
							</p>
						),
						icon: "info",
						showCloseButton: true,
						confirmButtonColor: "#0d6efd",
						confirmButtonText: "Entendido",
					});
				}

				setCursosUsuario(cursosUsuarioAux);
			} catch (error) {
				console.log("error parseando los cursos: ", error);
			}

			setShowLoader(false);
		}, 1700);

		const verificarCertificadoEmitido = async () => {
			try {
				const infoCertificado = {
					idPerfil: idPerfil,
					codigoCurso: codigoCursoSeleccionado,
					rol,
					idUsuarioEmpresarial,
					correo,
				};

				const { code, message, data } = await verificarCertificado(infoCertificado);

				console.log({ code, message, data });

				if (code === 200) {
					setCertificadoDesbloqueado(true);
				}
			} catch (error) {
				console.log(error);
				setCertificadoDesbloqueado(false);
			}
		};

		verificarCertificadoEmitido();
	}, []);

	useEffect(() => {
		if (infoCurso.tieneEvaluacionLeccion) {
			setHasLeccionesIndividuales(true);
		} else {
			setHasLeccionesIndividuales(false);
		}
	}, [infoCurso]);

	useEffect(() => {
		verificarEvaluacionLeccion(leccionActual);
	}, [preguntasEvaluacion]);

	useEffect(() => {
		const obtenerSeccionLeccionBreadcrumb = async () => {
			try {
				const { data } = await getLeccionByCodigo(leccionActual);
				setNombreLeccion(data.nombre);

				const { data: dataSeccion } = await getSeccionByCodigo(data.codigoSeccion);
				setNombreSeccion(dataSeccion.nombre);
			} catch (error) {
				console.log(error);
			}
		};
		obtenerSeccionLeccionBreadcrumb();
	}, [leccionActual]);

	useEffect(() => {
		//*VALIDACION TEMPORAL EN LO QUE SE REEMPLAZA MOODLE CON CLICK +
		let urlLeccionSeleccionada = ``;

		if (codigoCursoSeleccionado.startsWith("EA16")) {
			urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${codigoCursoSeleccionado}/EA16/${leccionActual}`;
		} else {
			// validacion cursos de invitado
			// si es diferente de undefined, sí es un curso de invitado
			const cursoInvitado = codigoCursosUsuarioInvitado.find((invitado) => invitado.codigoInvitado === codigoCursoSeleccionado);

			if (cursoInvitado !== undefined) {
				switch (cursoInvitado.codigoCurso) {
					case "EA16":
						let auxLeccionActual = `EA1611${leccionActual.substring(6, leccionActual.length)}`;

						let auxNivelLeccion = (parseInt(auxLeccionActual.substring(6, auxLeccionActual.length)) + 1).toString().padStart(2, "0");

						urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/EA16/EA1611${auxNivelLeccion}`;
						break;

					case "EX21":
						const seccionLeccion = leccionActual.substring(4, leccionActual.length - 2);

						let auxSeccionLeccion = "";

						switch (seccionLeccion) {
							case "01":
								auxSeccionLeccion = "0102";
								break;

							case "02":
								const nivelLeccionSeccion2 = leccionActual.substring(6, leccionActual.length);

								switch (nivelLeccionSeccion2) {
									case "01":
										auxSeccionLeccion = `0213`;
										break;

									case "02":
										auxSeccionLeccion = `0218`;
										break;

									default:
										break;
								}
								break;

							case "03":
								const nivelLeccionSeccion3 = leccionActual.substring(6, leccionActual.length);

								switch (nivelLeccionSeccion3) {
									case "01":
										auxSeccionLeccion = `0310`;
										break;

									case "02":
										auxSeccionLeccion = `0311`;
										break;

									default:
										break;
								}
								break;

							case "04":
								const nivelLeccionSeccion4 = leccionActual.substring(6, leccionActual.length);

								switch (nivelLeccionSeccion4) {
									case "01":
										auxSeccionLeccion = `0407`;
										break;

									case "02":
										auxSeccionLeccion = `0414`;
										break;

									case "03":
										auxSeccionLeccion = `0418`;
										break;

									case "04":
										auxSeccionLeccion = `0427`;
										break;

									default:
										break;
								}
								break;

							case "05":
								auxSeccionLeccion = "0610";
								break;

							default:
								break;
						}

						urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/EX21${auxSeccionLeccion}`;

						break;

					case "EI16":
						const nivelLeccionEI16 = leccionActual.substring(6, leccionActual.length);

						urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/EI1605${nivelLeccionEI16}`;
						break;

					case "PB21":
						const nivelLeccionPB21 = leccionActual.substring(6, leccionActual.length);

						urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/PB2109${nivelLeccionPB21}`;

						break;

					case "PBIA":
						const nivelLeccionPBIA = leccionActual.substring(6, leccionActual.length);

						urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/PBIA05${nivelLeccionPBIA}`;
						break;

					default:
						urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${cursoInvitado.codigoCurso}/${leccionActual}`;
						break;
				}
			} else {
				urlLeccionSeleccionada = `https://virtual.globalclickmexico.com/lecciones/sistemas/${codigoCursoSeleccionado}/${leccionActual}`;
			}
		}

		setUrlLeccion(urlLeccionSeleccionada);

		const consultarCurso = async () => {
			try {
				const { data, message } = await getCursoByCodigo(codigoCursoSeleccionado);
				if (data.length === 0) {
					return Notificacion("info", message);
				}

				setTieneMaterialApoyo(data.tieneMaterialApoyo);

				setInfoCurso(data);
			} catch (error) {
				console.log("Error al consultar la información del curso: ", error);

				NotificacionExtendida("error", "Algo sucedió al consultar las lecciones del curso, recarga la página");
			}
		};

		consultarCurso();

		const obtnerPreguntas = async () => {
			try {
				const { data } = await getPreguntasByCodigoCurso(codigoCursoSeleccionado);
				if (Array.isArray(data)) {
					setPreguntasEvaluacion(data);
				} else {
					setPreguntasEvaluacion([data]);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtnerPreguntas();

		const obtenerUrlPlantilla = async () => {
			try {
				const { data } = await getLeccionByCodigo(leccionActual);

				if (data.urlPlantilla !== "" || data.urlPlantilla !== undefined) {
					setUrlPlantilla(data.urlPlantilla);
				} else {
					setUrlPlantilla(null);
				}
			} catch (error) {
				console.log(error);
			}
		};

		obtenerUrlPlantilla();

		setTimeout(() => {
			setShowLoader(false);
		}, 1700);

		verificarEvaluacionLeccion(leccionActual);
	}, [leccionActual]);

	useEffect(() => {
		setLeccionActual(ultimoCursoTomado + "0101");

		setShowLoader(true);

		setTimeout(() => {
			setShowLoader(false);
		}, 1700);
	}, [codigoCursoSeleccionado]);

	useEffect(() => {
		const comprobarCertificacion = async () => {
			try {
				const { data } = await getCalificacionPorCurso({
					codigoCurso: codigoCursoSeleccionado,
					idPerfil,
					rol,
					idUsuarioEmpresarial,
				});
				setPuntajeCertificacion(data.puntajeCertificacion);
				if (data.puntajeCertificacion >= 85) {
					setCertificadoDesbloqueado(true);
				}
			} catch (error) {
				console.log(error);
			}
		};

		comprobarCertificacion();
	}, [showEvaluacionFinal]);

	useEffect(() => {
		const comprobarCertificacion = async () => {
			try {
				const { data } = await getCalificacionPorCurso({
					codigoCurso: codigoCursoSeleccionado,
					idPerfil,
					rol,
					idUsuarioEmpresarial,
				});
				setPuntajeCertificacion(data.puntajeCertificacion);
				if (data.puntajeCertificacion >= 85) {
					setCertificadoDesbloqueado(true);
				}
			} catch (error) {
				console.log(error);
			}
		};

		if (certificadoDesbloqueado) {
			comprobarCertificacion();
		}
	}, [certificadoDesbloqueado]);

	const handleVerLecturaVeloz = async () => {
		try {
			let newWindow = window.open(`https://virtual.globalclickmexico.com/lectura/initCurso.php?nombreUsuario=${usuarioLogeado.correo}`, "_blank");

			newWindow.focus();
		} catch (error) {
			console.log("Error al intentar iniciar sesión en lectura veloz: ", error);
		}
	};

	const handleDescargaMaterialApoyo = async () => {
		try {
			const request = await fetch(`${window.location.origin}/assets/materialApoyo/${codigoCursoSeleccionado}.pdf`);

			const response = await request.blob();

			const url = window.URL.createObjectURL(new Blob([response]));

			const link = document.createElement("a");

			link.href = url;

			link.setAttribute("download", `${infoCurso.nombre}.pdf`);

			// Append to html link element page
			document.body.appendChild(link);

			// Start download
			link.click();

			// Clean up and remove the link
			link.parentNode.removeChild(link);
		} catch (error) { }
	};

	return (
		<>
			{showLoader ? (
				<Loader height={"80vh"} setShowLoader={setShowLoader} />
			) : (
				<>
					{showScrollButton && (
						<Animated animationIn="fadeIn" isVisible={true} style={{ zIndex: "9999", position: "absolute" }}>
							<div className="scroll-top">
								<span
									className={`rounded-circle scrollTop text-center d-flex`}
									style={{ height: "50px", width: "50px", fontSize: "2rem", backgroundColor: "#F59E0B", position: "fixed", bottom: "70px", right: "40px" }}>
									<LinkScroll className="pointer" activeClass="active" to="scrollTopElement" spy={true} smooth={true} duration={800} containerId="scrollTop" style={{ color: "black", width: "100%" }}>
										<FontAwesomeIcon icon={faArrowUp} />
									</LinkScroll>
								</span>
							</div>
						</Animated>
					)}

					{showEvaluacionFinal ? (
						<EvaluacionFinal
							FullScreenButton={FullScreenButton}
							cancelFullScreenButton={cancelFullScreenButton}
							modoConcentracion={modoConcentracion}
							setModoConcentracion={setModoConcentracion}
							setPuntajeCertificacion={setPuntajeCertificacion}
							rol={rol}
							idUsuarioEmpresarial={idUsuarioEmpresarial}
							idPerfil={idPerfil}
							setCertificadoDesbloqueado={setCertificadoDesbloqueado}
							infoCurso={infoCurso}
							codigoCurso={codigoCursoSeleccionado}
							setShowEvaluacionFinal={setShowEvaluacionFinal}
						/>
					) : (
						<Container className={`contenedor-curso p-3 mt-4 ${modoConcentracion && "pt-5"}`} fluid>
							<Joyride
								locale={spanishOptionsJoyRide}
								ref={joyrideRef}
								steps={tourSteps}
								run={runTour}
								continuous={true}
								showSkipButton
								hideCloseButton
								disableOverlayClose={true}
								disableCloseOnEsc={false}
								disableScrolling={false}
								scrollToFirstStep={true}
								scrollOffset={100}
								callback={(data) => {
									if (data.status === "finished") {
										setRunTour(false);
									} else if (data.action === "skip") {
										setRunTour(false);
									}
								}}
								styles={{
									options: {
										primaryColor: "#ff9900",
										textColor: "#00152A",
										width: 900,
										zIndex: 1000,
									},
								}}
							/>

							<Row className="d-flex align-items-center justify-content-between">
								<Col className="col-auto">
									<Row className="d-flex align-items-center">
										<Col className="col-auto px-0">
											<DynamicBreadcrumb arrayBreadcrumbs={breadcrumbItemsInitialState} />
										</Col>
									</Row>
								</Col>

								<Col className="col-auto">
									<Row className="d-flex justify-content-end">
										<Col className="col-auto">
											{!showEvaluacionLeccion &&
												!codigoCursoSeleccionado.startsWith("ING") &&
												!codigoCursoSeleccionado.startsWith("LV23") &&
												(!modoConcentracion ? (
													<OverlayTrigger className="overlay-trigger " placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Modo concentración.</Tooltip>}>
														<Button className="modo-concentracion" variant="outline-success" onClick={FullScreenButton}>
															<FaSpa />
														</Button>
													</OverlayTrigger>
												) : (
													<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Salir del modo concentración.</Tooltip>}>
														<Button variant="outline-danger" onClick={cancelFullScreenButton}>
															<MdOutlineCancel />
														</Button>
													</OverlayTrigger>
												))}
										</Col>

										{!modoConcentracion && (
											<>
												{rol !== typesTiposUsuario.lecturaVeloz && (
													<Col className="col-auto listado-curso p-0 m-0">
														<Button className="pointer" variant="outline-info" onClick={verCursos}>
															<FontAwesomeIcon className="mx-1" icon={faArrowLeft} /> Ver todos mis cursos
														</Button>
													</Col>
												)}

												<Col className="col-auto">
													{width > 768 && rol !== typesTiposUsuario.lecturaVeloz && (
														<Col className="col-auto question-curso end-curso">
															<OverlayTrigger placement={"top"} overlay={<Tooltip>Conoce tu entorno</Tooltip>}>
																<span>
																	<FontAwesomeIcon className="mx-1 pointer" onClick={handleStartTour} style={{ fontSize: "2rem" }} icon={faQuestion} />
																</span>
															</OverlayTrigger>
														</Col>
													)}
												</Col>
											</>
										)}
									</Row>
								</Col>
							</Row>

							{(rol === typesTiposUsuario.invitado || rol === typesTiposUsuario.lecturaVeloz) && (
								<Row className="mb-5">
									<Col>
										<Button
											variant="warning"
											onClick={() => {
												handleChangeComponent(multiViewComponentsPaths.solicitudLlamadaVenta);
											}}>
											Adquirir Licencia Premium
										</Button>
									</Col>
								</Row>
							)}

							{/* //*Fila para los botones de la vista ====================================================*/}

							<Row className="mb-2 mt-5">
								<Col className="col-7">
									<Row className="justify-content-start justify-content-lg-end">
										<Col className="col-auto">
											{!showEvaluacionLeccion && !codigoCursoSeleccionado.startsWith("ING") && !codigoCursoSeleccionado.startsWith("LV23") && (
												<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Pantalla completa.</Tooltip>}>
													<Button className="btn-fullscreen" onClick={requestFullscreen} variant="secondary">
														<FontAwesomeIcon icon={faExpand} />
													</Button>
												</OverlayTrigger>
											)}
										</Col>
									</Row>
								</Col>

								<Col align="right">
									<Row className="justify-content-end">
										<Col>
											{urlPlantilla !== "" && urlPlantilla !== null && !cursosSinPlantilla.includes(codigoCursoSeleccionado) && (
												<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Descargar plantilla.</Tooltip>}>
													<a
														className="btn btn-primary mx-2"
														width="100%"
														height="100%"
														target="_blank"
														rel="noreferrer"
														href={
															//*VALIDACION TEMPORAL PARA EA16
															codigoCursoSeleccionado.startsWith("EA16")
																? `https://virtual.globalclickmexico.com/lecciones/sistemas/${codigoCursoSeleccionado}/EA16/${leccionActual}/${urlPlantilla}`
																: `https://virtual.globalclickmexico.com/lecciones/sistemas/${codigoCursoSeleccionado}/${leccionActual}/${urlPlantilla}`
														}>
														<FaDownload />
													</a>
												</OverlayTrigger>
											)}
											{tieneMaterialApoyo ? (
												<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Descargar Ebook del curso.</Tooltip>}>
													<Button variant="primary" onClick={handleDescargaMaterialApoyo}>
														<FaBookOpen />
													</Button>
												</OverlayTrigger>
											) : (
												<span></span>
											)}

											{mostrarBtnEvaluacion &&
												hasLeccionesIndividuales &&
												(showBtnEvaluacion ? (
													<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Hacer la evaluación de la lección.</Tooltip>}>
														<Button
															variant="primary"
															onClick={() => {
																if (rol === typesTiposUsuario.invitado) {
																	return mostrarAlertaPremium();
																}

																setShowEvaluacionLeccion(true);

																setShowBtnEvaluacion(false);
															}}
															className="mx-1 evaluacionLeccion-curso">
															<FaFile />
														</Button>
													</OverlayTrigger>
												) : (
													<OverlayTrigger className="overlay-trigger" placement={"top"} overlay={<Tooltip id={`tooltip-bottom}`}>Volver a la lección.</Tooltip>}>
														<Button
															variant="danger"
															onClick={() => {
																setShowEvaluacionLeccion(false);
																setShowBtnEvaluacion(true);
															}}
															className="mx-1">
															<FaArrowCircleLeft />
														</Button>
													</OverlayTrigger>
												))}
										</Col>
									</Row>
								</Col>
							</Row>

							{codigoCursoSeleccionado !== "LV23" ? (
								<>
									<Row className="mt-3">
										<Col className={`col-12 col-lg-7 p-0  ${!codigoCursoSeleccionado.startsWith("ING") ? "d-block" : "d-none"}`}>
											{showEvaluacionLeccion ? (
												<EvaluacionLeccion
													setShowBtnEvaluacion={setShowBtnEvaluacion}
													setShowEvaluacionLeccion={setShowEvaluacionLeccion}
													codigoLeccion={leccionActual}
													setCertificadoDesbloqueado={setCertificadoDesbloqueado}
												/>
											) : !cursosFueraComponente.includes(codigoCursoSeleccionado) ? (
												<ComponenteEmbebido urlLeccion={urlLeccion} iframeRef={iframeRef} idPerfil={idPerfil} leccionActual={leccionActual} tipoLeccion={tipoLeccion} />
											) : (
												// <div className="embed-responsive embed-responsive-16by9 visor-curso">
												// 	<iframe
												// 		style={{ border: "none" }}
												// 		allow="autoplay; encrypted-media;fullscreen"
												// 		loading="lazy"
												// 		title="Lección actual"
												// 		ref={iframeRef}
												// 		className="embed-responsive-item iframe-curso"
												// 		src={urlPlantilla}
												// 		onLoad={aplicarEstilosAlIframe}
												// 	></iframe>
												// </div>

												<div className="ratio ratio-16x9 visor-curso">
													<iframe
														style={{ border: "none" }}
														allow="autoplay; encrypted-media;fullscreen"
														loading="lazy"
														title="Lección actual"
														ref={iframeRef}
														className="embed-responsive-item iframe-curso"
														src={urlPlantilla}></iframe>
												</div>
											)}
										</Col>

										<Col className={`contenedor-secciones contenido-curso  ${codigoCursoSeleccionado.startsWith("ING") ? "col-12" : "col-12 col-lg-5"}`}>
											{/* VALIDAR SI ES CURSO DE INGLES Y MOSTRAR UN COMPONENTE PARA DESPLEGAR LAS UNIDADES EN VEZ DE COMO SI FUERA UNO DE */}
											{codigoCursoSeleccionado.startsWith("ING") ? (
												<DespliegeUnidadesLecciones
													setShowBtnEvaluacion={setShowBtnEvaluacion}
													codigoCurso={codigoCursoSeleccionado}
													setUrlLeccion={setUrlLeccion}
													verificarEvaluacionLeccion={verificarEvaluacionLeccion}
													setLeccionActual={setLeccionActual}
													setShowEvaluacionLeccion={setShowEvaluacionLeccion}
													leccionActual={leccionActual}
													handleScrollToTop={handleScrollToTop}
													showBtnEvaluacion={showBtnEvaluacion}
													idPerfil={idPerfil}
												/>
											) : (
												<DespliegueSeccionesLecciones
													setShowBtnEvaluacion={setShowBtnEvaluacion}
													codigoCurso={codigoCursoSeleccionado}
													setUrlLeccion={setUrlLeccion}
													verificarEvaluacionLeccion={verificarEvaluacionLeccion}
													setLeccionActual={setLeccionActual}
													setShowEvaluacionLeccion={setShowEvaluacionLeccion}
													leccionActual={leccionActual}
													handleScrollToTop={handleScrollToTop}
													showBtnEvaluacion={showBtnEvaluacion}
													idPerfil={idPerfil}
													setNombreLeccionSeleccionada={setNombreLeccionSeleccionada}
													mostrarDocumento={mostrarDocumento}
													tipoLeccion={tipoLeccion}
													setNombreSeccion={setNombreSeccion}
													setListadoLecciones={setListadoLecciones}
													refSiguienteLeccion={siguienteLeccionRef}
													refLeccionAnterior={leccionAnteriorRef}
													refSiguienteSeccion={siguienteSeccionRef}
													refSeccionAnterior={seccionAnteriorRef}
												/>
											)}
										</Col>
									</Row>

									{!codigoCursoSeleccionado.startsWith("ING") && (
										<Row className="mb-4 mt-4 d-none d-lg-block">
											<Col className="col-7">
												<Row className="justify-content-around">
													<Col className="col-auto">
														<Button onClick={leccionAnterior} variant="warning" className="boton-lecciones">
															<FaArrowLeft /> Lección anterior
														</Button>
													</Col>
													<Col className="col-auto">
														<Button onClick={siguienteLeccion} variant="warning" className="boton-lecciones">
															Siguiente lección <FaArrowRight />
														</Button>
													</Col>
												</Row>
											</Col>
											<Col className="col-5">
												<span></span>
											</Col>
										</Row>
									)}

									{/* //*Fila principal de la vista =====================================================*/}

									{!modoConcentracion && (
										<Row className="mb-5">
											<Col>
												<Tabs defaultActiveKey="contentCurso" id="uncontrolled-tab-example" activeKey={tab} onSelect={(k) => setTab(k)} className="my-3 test">
													<Tab eventKey="contentCurso" title="Certificación">
														<Container fluid>
															<Row>
																<Col md={infoCurso.tieneEvaluacion ? 8 : 12} xs={12} className="mt-3">
																	{infoCurso.tieneEvaluacion ? (
																		<>
																			<Row className="evaluacion-curso">
																				<Col className="mb-3">
																					<h3>Evaluación final</h3>
																				</Col>
																				<Col className="col-12">
																					<Alert variant="warning">
																						Antes de emitir tu certificado asegúrate de que tus datos sean los correctos.
																						<br />
																						<b>Calificación mínima aprobatoria: 85%</b>
																					</Alert>
																				</Col>
																				<Col className="mt-1 col-12">
																					{codigoCursoSeleccionado.startsWith("ING") ? (
																						<a
																							className="btn btn-primary"
																							href={`https://virtual.globalclickmexico.com/lecciones/ingles/leccionesEvalModulo.php?modulo=${codigoCursoSeleccionado}&email=${correo}&leccion=${codigoCursoSeleccionado + "EM"}&idPerfil=${idPerfil}`}
																							target="_blank"
																							rel="noreferrer">
																							Evaluación final
																						</a>
																					) : (
																						<Button
																							className="btn-sm"
																							onClick={() => {
																								if (rol === typesTiposUsuario.invitado) {
																									return mostrarAlertaPremium();
																								}

																								setShowEvaluacionFinal(true);
																							}}>
																							Evaluación final
																						</Button>
																					)}
																				</Col>
																			</Row>

																			<GenerarQR
																				comprobarCertificacion={comprobarCertificacion}
																				codigoCursoSeleccionado={codigoCursoSeleccionado}
																				rol={rol}
																				idUsuarioEmpresarial={idUsuarioEmpresarial}
																				puntajeCertificacion={puntajeCertificacion}
																				codigoCurso={codigoCursoSeleccionado}
																				certificadoDesbloqueado={certificadoDesbloqueado}
																				usuarioLogeado={usuarioLogeado}
																				infoCurso={infoCurso}
																			/>
																		</>
																	) : (
																		<>
																			{codigoCursoSeleccionado !== "LV23" && (
																				<>
																					{codigoCursoSeleccionado === 'PBIA' && (
																						<Row>
																							<Col>
																								<CertificadosPowerBI infoCurso={infoCurso} usuarioLogeado={usuarioLogeado} leccionActual={leccionActual} idPerfil={idPerfil} />
																							</Col>
																						</Row>
																					)}
																					<Row>
																						<Col className="text-center mt-5 mb-5" style={{ color: "grey" }}>
																							<FontAwesomeIcon icon={faChalkboardTeacher} className="mb-3 icon" size="4x" />
																							<h4>Este curso no cuenta con una evaluación final</h4>
																						</Col>
																					</Row>
																				</>
																			)}
																		</>
																	)}
																</Col>

																<Col md={infoCurso.tieneEvaluacion ? 4 : 12} xs={12} className="mt-3">
																	{/* //*ESTE BOTON ES PARA VER LOS AVANCES UNICAMENTE DEL CURSO DE INGLES */}
																	{codigoCursoSeleccionado.startsWith("ING") && (
																		<Button onClick={verAvancesIngles} className="mb-3" block>
																			Ver avance del curso
																		</Button>
																	)}

																	{rol !== typesTiposUsuario.invitado && codigoCursoSeleccionado !== "LV23" && (
																		<>{infoCurso.tieneEvaluacion ? <RankingTopCurso codigoCurso={codigoCursoSeleccionado} /> : <span></span>}</>
																	)}
																</Col>
															</Row>
														</Container>
													</Tab>

													<Tab eventKey="aboutCurso" title="Acerca del curso">
														<AcercaDelCurso rol={rol} tab={tab} infoCurso={infoCurso} />
													</Tab>
												</Tabs>
											</Col>
										</Row>
									)}
								</>
							) : (
								<>
									{/* {rol === typesTiposUsuario.lecturaVeloz && (
										<> */}
									<TitleDashboard title="Conoce el curso" />

									<Row className="mb-5">
										<Col>
											<video className="pt-4 h-100 w-100" controls>
												<source src="https://globalclickmexico.com/img/intro_lectura_veloz.mp4" type="video/mp4" />
											</video>
										</Col>

										<Col>
											<div className="flex flex-wrap justify-content-center gap-2">
												<Card
													className="mt-4"
													footer={
														<Button variant="success" block onClick={handleVerLecturaVeloz}>
															<FontAwesomeIcon icon={faPlay} /> Ir al curso
														</Button>
													}
													header={<img alt="Card" src="https://primefaces.org/cdn/primereact/images/usercard.png" />}></Card>
											</div>
										</Col>
									</Row>
									{/* </>
									)} */}
								</>
							)}
						</Container>
					)}
				</>
			)}
		</>
	);
};

export default ViewCurso;
