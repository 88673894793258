import React from 'react'
import { Button, Col, Container, FloatingLabel, Form, InputGroup, Row } from 'react-bootstrap';
import { Box, Modal, Typography } from '@mui/material'
import {eliminarRegistro} from '@helpers/httpRequests/httpRequestAccesos/httpRequestAccesos';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EliminarRegistro = ({infoRegistro, setRegistroEliminado, handleCancelarEliminar, verModalEliminar}) => {

    const {idRegistro} =infoRegistro;

    const deleteRegistro = async() => {
        try{
            await eliminarRegistro(idRegistro)
            setRegistroEliminado(true);
            handleCancelarEliminar();
        }catch(error){
            console.log(error);
        }
    }

    const cancelar = () => {
        handleCancelarEliminar();
    }

  return (
    <Modal
            open={verModalEliminar}
            onClose={handleCancelarEliminar}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Container>
                    <Row className="mt-4">
                        <Col className="text-center mt-3">
                            <h2>¿Estás seguro de eliminar el registro seleccionado?</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-around mt-3">
                        <Col className="col-auto">
                            <Button onClick={deleteRegistro} className="btn btn-success">Si, eliminar</Button>
                        </Col>
                        <Col className="col-auto">
                            <Button onClick={cancelar} className="btn btn-danger">Cancelar</Button>
                        </Col>
                    </Row>

                </Container>
            </Box>
        </Modal>
  )
}

export default EliminarRegistro;