import useWindowResize from '@customHooks/useWindowResize';
import { Alert } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useLocation, useParams } from 'react-router-dom'

const ReproductorAudios = () => {

    const [urlAudio, setUrlAudio] = useState('');

    const param = useLocation();

    const [anchoPantalla, setAnchoPantalla] = useState(0);

    const [theme, setTheme] = useState('light');
    
    const [device, setDevice] = useState('');

    const [nombreCurso, setNombreCurso] = useState('');

    const [nombreLeccion, setNombreLeccion] = useState('');

    useEffect(() => {

        const ancho = window.innerWidth;

        const {search} = param;

        const searchParams = new URLSearchParams(search);
        const url = searchParams.get('track');
        const theme = searchParams.get('theme');
        const device = searchParams.get('device');
        const nombreLeccion = searchParams.get('nombreLeccion');
        const nombreCurso = searchParams.get('cursoSeleccionado');
        
        setAnchoPantalla(ancho)
        setUrlAudio(url);
        setTheme(theme);
        setDevice(device);
        setNombreCurso(nombreCurso);
        setNombreLeccion(nombreLeccion);

    }, [])
    


    return (
        <Container className='fluid pb-5 ' style={{backgroundColor: theme === 'dark' ? '#121212' : '#fff', height: "100vh" }}>
            <Row className={device === 'ios' ? `pt-4` : `pt-3`}>
                <Col className='col-12 text-center'>
                    <Image style={{ borderRadius: '10px' }} src={require('@assets/images/miniaturaAudio.gif')} width={anchoPantalla * .75} />
                </Col>
            </Row>
            <Row className="mt-2 p-3">
                <Col className={theme === 'dark' ? `text-white` : `text-dark`}>
                    {device === 'ios' ? (
                       <>
                            <h3>{nombreCurso}</h3>
                            <h6>{nombreLeccion}</h6>
                        </>
                    ): (
                        <>
                            <h4>{nombreCurso}</h4>
                            <h5>{nombreLeccion}</h5>
                        </>
                    )}
                    
                </Col>
            </Row>
            <Row className="mt-2 px-3">
                <Col className="col-12">
                    {urlAudio !== '' && (
                        <audio style={{width: '100%',height: '50px'}} controls>
                        <source src={urlAudio} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                    )}
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Alert severity="info">Puedes dejar la app en segundo plano o apagar tu celular mientras reproduces esta lección.</Alert>
                </Col>
            </Row>
        </Container>
    )
}

export default ReproductorAudios 