import httpRequestsPaths from "@helpers/httpRequestsPaths/httpRequestsPaths";
import endpointsPaths from "../endpointsPaths/endpointsPaths";
import httpRequest from "../httpRequest";

export const getAccesoAdministrador = (datosAdministrador) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await httpRequest({
                url: `${ endpointsPaths.accesosAdministradores }/${ httpRequestsPaths.accesosAdministradores.getAccesos }`,
                method: 'POST',
                body: JSON.stringify(datosAdministrador)
            });

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};



export const InsertarRegistroAdministradores = (infoRegistro) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await httpRequest({
                url: `${ endpointsPaths.accesosAdministradores }/${ httpRequestsPaths.accesosAdministradores.insertarRegistroAdministradores }`,
                method: 'POST',
                body: JSON.stringify(infoRegistro),
            });

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};


export const editarRegistroAdministradores = (infoRegistro) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await httpRequest({
                url: `${ endpointsPaths.accesosAdministradores }/${ httpRequestsPaths.accesosAdministradores.editarRegistroAdministradores }`,
                method: 'POST',
                body: JSON.stringify(infoRegistro),
            });

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

export const eliminarRegistro = (idRegistro) => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = await httpRequest({
                url: `${ endpointsPaths.accesosAdministradores }/${ httpRequestsPaths.accesosAdministradores.eliminarRegistro }/${idRegistro}`,
                method: 'POST',
            });

            resolve(response);
        } catch (error) {
            reject(error);
        }
    });
};

