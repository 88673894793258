import getUnixTimestamp from '@helpers/dates/getUnixTimestamp/getUnixTimestamp';
import { getAccesoAdministrador, getRegistrosAdministradores } from '@helpers/httpRequests/httpRequestAccesos/httpRequestAccesos';
import { Box, Modal, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import getDateFromUnix from '../../../../helpers/dates/getDateFromUnix/getDateFromUnix';
import { sendAccesoConcedido, sendIntentoAccesoAdministradores } from '@helpers/httpRequests/httpRequestsMailers/httpRequestsMailers';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ModalValidacion = ({ open, handleClose,setValidado, setRegistros, setRegistroInsertado, setRegistroModificado, setRegistroEliminado, registroInsertado, registroEliminado, registroModificado }) => {

    const [formValues, setFormValues] = useState({
        username: '',
        password: '',
    })

    const [camposVacios, setCamposVacios] = useState(false);

    const [showPassword, setShowPassword] = useState(false);

    const handleOnChange = ({ currentTarget }) => {
        const { name, value } = currentTarget;

        setFormValues({
            ...formValues,
            [name]: value,
        })
    }

    const handleSubmit = async(e) => {
        e.preventDefault();
        const fecha = getUnixTimestamp();
        const fechaFormateada = getDateFromUnix(fecha)
        const {username, password} = formValues;
        try{

            if(username !== '' && password !== ''){
                try{
                    const response = await getAccesoAdministrador({
                        user: username,
                        password,
                        fechaActual: getDateFromUnix(fecha),
                    })
    
    
    
                    if(response.code === 200) {
                        const {data} = response.data;
                        if(Array.isArray(data)){
                            setRegistros(data);
                        }else{
                            setRegistros([data]);
                        }

                        if(!registroModificado && !registroEliminado && !registroInsertado){
                            await sendAccesoConcedido({
                                user: username,
                                fechaActual: fechaFormateada
                            })
                        }
                        setValidado(true);
                        handleClose();
                    };
                }catch (e){
                    if(e.message !== "error_info"){
                        await sendIntentoAccesoAdministradores({
                            user: username,
                            fechaActual: fechaFormateada
                        })
                    }else{
                        setValidado(true);
                        setRegistros([])
                        handleClose();
                    }
                    
                }
               
                setRegistroInsertado(false);
                setRegistroModificado(false);
                setRegistroEliminado(false);

               

            }
        }catch (error){
            setCamposVacios(true);
            console.log(error)
            

        }
    }

    const handleShowPassword = () =>{
        setShowPassword(!showPassword);
    }

    useEffect(() => {
        setFormValues({
            ...formValues,
            username: '',
            password: '',
        })
    }, [handleClose])
    

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Se requiere validar tus credenciales
                    </Typography>
                    <Container>
                        <Row className="mt-4">
                            <Col>
                                <Form onSubmit={handleSubmit}>
                                    <Form.Label htmlFor="inputPassword5">Nombre de usuario</Form.Label>
                                    <Form.Control
                                        autoComplete='off'
                                        list='suggestions'
                                        className="mb-3"
                                        name="username"
                                        value={formValues.username}
                                        onChange={handleOnChange}
                                        type="text"
                                        id="username"
                                        aria-describedby="passwordHelpBlock"
                                    />

                                    <Form.Label htmlFor="inputPassword5">Contraseña</Form.Label>
                                    <Form.Control
                                        className="mb-3"
                                        name="password"
                                        value={formValues.password}
                                        onChange={handleOnChange}
                                        type={showPassword ? 'text' : 'password'}
                                        id="inputPassword5"
                                        aria-describedby="passwordHelpBlock"
                                    />

                                    <span style={{cursor: 'pointer'}} onClick={handleShowPassword} className="mb-4">{showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}</span>

                                    <div className='d-grid gap-2 mt-3'>
                                        <Button type="submit"   className='btn btn-success'>Continuar</Button>
                                    </div>
                                </Form>

                            </Col>
                        </Row>
                    </Container>
                </Box>
            </Modal>
        </div>
    )
}

export default ModalValidacion